import Axios from 'axios';
import moment from 'moment';
import {mask} from 'vue-the-mask';

export default {
  data() {
    return {
      TOKEN: this.$store.state.token,
      me: this.$store.state.me,
      hiring: {
        company: {
          address: [],
        },
        user: {},
        providers: [],
      },
      provider: {
        profile: {
          city: {},
          state: {},
          country: {},
        },
        legal: {},
        financial: {},
      },
      legal: {},

    }
  },

  mounted() {
    var _this = this;
    // if (_this.$store.state.role == 'admin') _this.$router.push({ path: '/dashboard/admin' });
    // if (_this.$store.state.role == 'professional') _this.$router.push({ path: '/dashboard/professional' });

    if (this.hiring.company_id) {
      axios
        .get('/api/v1/companies/' + this.hiring.company_id, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          if (!response.data.error) {
            _this.hiring.company = response.data.data;
          } else {
            console.log(response.data.error);
          }
      });
    } else {
      _this.provider = this.me;
    }

    axios
      .get('/api/v1/hirings/' + _this.$route.params.id, {
        headers: {
          Authorization: 'Bearer ' + _this.TOKEN,
        }
      })
      .then(response => {
        if (!response.data.error) {
          _this.hiring = response.data.data;
          _this.hiring.message = _this.hiring.message.replace(/(?:\r\n|\r|\n)/g, '<br>');
          _this.hiring.tasks = _this.hiring.tasks.replace(/(?:\r\n|\r|\n)/g, '<br>');
          if (_this.$route.query.professional) {
            axios
              .get('/api/v1/users/' + _this.$route.query.professional, {
                headers: {
                  Authorization: 'Bearer ' + _this.TOKEN,
                }
              })
              .then(response => {
                if (!response.data.error) {
                  _this.provider = response.data.data;
                } else {
                  console.log(response.data.error);
                }
            });
          } else {
            axios
              .get('/api/v1/users/' + _this.hiring.provider_id, {
                headers: {
                  Authorization: 'Bearer ' + _this.TOKEN,
                }
              })
              .then(response => {
                if (!response.data.error) {
                  _this.provider = response.data.data;
                } else {
                  console.log(response.data.error);
                }
            });
          }
        }
    });

  },

  computed: {
    projectName() {
      return '#' + this.hiring.id + '-' + moment(this.hiring.created_at).format('Y');
    },
    avatar() {
      return this.$store.getters.avatar;
    },
    role() {
      return this.$store.state.role;
    },

    pivot() {
      let _this = this;
      if (!_this.hiring.providers.length) return {};
      let found = _this.hiring.providers.find(function (element) {
        return element.id == _this.provider.id;
      });
      if (found) return found.pivot;
      return {};
    },

    professionalPrice() {

      var value = this.hiring.approved_value ? this.hiring.approved_value : (this.pivot ? this.pivot.value : 0);

      // Pernod Ricard tem 0,6% sobre o valor final
      if (this.hiring.company_id == 159) value = value * 1.006;

      return value;
    },

  },

  methods: {
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },

    toCurrency(val) {
      let value = val * 1;
      let currency = "BRL";
      if (this.me.id != this.hiring.provider_id && this.hiring.company.currency == "eur") {
        value = val / this.$store.state.currencies.eur;
        currency = "EUR";
      }
      if (this.me.id != this.hiring.provider_id && this.hiring.company.currency == "usd") {
        value = val / this.$store.state.currencies.usd;
        currency = "USD";
      }
      return (value/100).toLocaleString("pt-BR", {style: "currency", currency: currency, minimumFractionDigits: 2});
    },

    costByPlan(value, plan) {
      switch (plan) {
        case 1:
          value = value / 0.85;
          break;
        case 2:
          value = value / 0.85;
          break;
        case 3:
          value = value / 0.75;
          break;
        case 4:
          value = value / 0.8928571429;
          break;
        case 5:
          value = value;
          break;
        case 6:
          value = value / 0.70;
          break;
        case 7:
          value = value / 0.20;
          break;
        default:
        value = value / 0.85;
      }

      // Pernod Ricard tem 0,6% sobre o valor final
      if (this.hiring.company_id == 159) value = value * 1.006;

      return value;
    },

    feeByPlan(value, plan) {
      switch (plan) {
        case 1:
          value = value / 0.85 * 0.15;
          break;
        case 2:
          value = value / 0.85 * 0.15;
          break;
        case 3:
          value = value / 0.75 * 0.25;
          break;
        case 4:
          value = value * 0.12;
          break;
        case 5:
          value = 0;
          break;
        case 6:
          value = value / 0.70 * 0.30;
          break;
        case 7:
          value = value / 0.80 * 0.20;
          break;
        default:
        value = value / 0.85 * 0.15;
      }
      return value;
    },

    now() {
      return moment().format('DD/MM/YYYY');
    }

  },

}
